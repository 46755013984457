import { Swiper, Navigation, Autoplay } from 'swiper';
Swiper.use(Navigation);
import Base from '@/core/Base'
import withState from '@/mixins/withState'
@withState

export default class extends Base {
	static targets = ['btnPrev', 'btnNext', 'slider', 'slide']

	onUpdate({ isReady: prevIsReady }, { isReady }) {
		if (!prevIsReady && isReady) {
			this.initCarousel()
		}
	}
	initCarousel() {
		let slidesPerView = 1.1
		if (this.slideTargets.length === 1) {
			slidesPerView = 1
		}
		this.swiper = new Swiper(this.sliderTarget, {
			slidesPerView: slidesPerView,
			watchOverflow: true,
			spaceBetween: 15,
			breakpoints: {
				1024: {
					slidesPerView: 1,
					spaceBetween: 0
				}
			},
			navigation: {
				nextEl: this.btnNextTarget,
				prevEl: this.btnPrevTarget
			}
		})

		this.swiper.init()
	}
}