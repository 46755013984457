import Base from '@/core/Base';

export default class extends Base {
	initialize() {
		const langSelectors = document.querySelectorAll('.footer-current-language')
		langSelectors.forEach(langSelector => {
			langSelector.addEventListener('click', () => {
				langSelector.parentElement.querySelector('.footer-languages-toggle').classList.toggle('active')
			})
		})
	}
}